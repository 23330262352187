<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="api-request-cmp relative-position">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="bg-red-9 text-white">
      <q-card-section>{{name}}</q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="result"/>
      <ab-flow-link-connector :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from '@/components/DiagramDesigner/Editor/components/renderMixins';

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: ['canvas'],
  props: ['block'],
  name: "ApiRequestEditorCmp",
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    name() {
      const { title, method } = this.block?.properties || {};

      return `${method || 'GET'}: ${this.interpretString(title) || 'n/a'}`;
    },
  }
}

</script>
