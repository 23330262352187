<template>

  <ab-flow-base-cmp class="navigation-panel row" :block="block" :class="cmpClasses" :style="stylesString">
    <q-tabs
      v-model="tab"
      class="col"
      no-caps
      :switch-indicator="selPosition"
      :vertical="vertical"
      :inline-label="vertical"
      :style="{
        '--tabs-justify-content': tabsAlign
      }"
    >
      <template v-if="!isCollapsed">
        <template v-for="(item, i) of itemsList" :key="item.title">
          <q-tab :icon="getIcon(item)" :name="item.value">
            <label>{{item.title}}</label>
            <ab-flow-link-connector class="link-connector" :key="i" :block="block" type="output" :event="item.value"/>
          </q-tab>
        </template>
      </template>

      <q-btn-dropdown
        v-else
        class="navigation-panel-dropdown"
        auto-close
        stretch
        flat
        icon="menu"
        persistent

      >
        <q-list :class="cmpClasses">
          <template v-for="item of itemsList" :key="item.value">
            <q-item clickable>
              <q-item-section v-if="getIcon(item)" avatar style="min-width: 0;">
                <q-icon :name="getIcon(item)" />
              </q-item-section>

              <q-item-section>{{ item.title }}</q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
    </q-tabs>

    <div v-if="isCollapsed" class="hidden-events">
      <ab-flow-link-connector
        v-for="(item, i) of itemsList"
        :key="i"
        class="link-connector"
        :block="block"
        type="output"
        :event="item.value"
      />
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {pathHelper} from '@/utils/pathHelper';

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  mixins: [renderMixins],
  props: ['block'],
  name: "NavigationPanelEditorCmp",
  data: () => ({
    tab: "",
    tabWidths: {},
    tabsContainerWidth: 0,
  }),

  computed: {

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * If up position
     * @return {boolean}
     */
    selPosition() {
      return ['right', 'down'].includes(this.block?.properties?.position)
    },

    /**
     * Items list
     * @return {*}
     */
    itemsList() {

      // get items from node
      const dynItems = (this.getValue(this.block?.properties?.dynamicItems) || [])?.filter(item => item && typeof item === 'object')?.map(item => ({
          title: item.title || item.label || item.name || "No Name",
          value: item.value || item.id || "No value",
        }));

      // return items
      return [...(this.block?.properties?.items?.items || []).map((item) => ({
        ...item,
        title: this.interpretString(item.title),
      })), ...dynItems]
    },


    /**
     * Button color
     * @return {string}
     */
    buttonColor() {
      return this.block?.properties?.color || "";
    },

    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * Computes the classes to be applied to the component.
     * It combines the classes from the getClasses method and additional classes
     * based on the disableJustifyAlignment and tabsAlign properties.
     *
     * @returns {Array} An array of class names.
     */
    cmpClasses() {
      return [
        this.getClasses(false, {contentAlign: true}),
        {
          'tabs-disable-justify-align': this.disableJustifyAlignment,
          'tabs-justify-content': !!this.tabsAlign,
        }
      ];
    },

    /**
     * Determines if the justify alignment should be disabled.
     * This is based on the `disableJustifyAlignment` property of the block.
     *
     * @returns {boolean} True if justify alignment is disabled, otherwise false.
     */
    disableJustifyAlignment() {
      return this.block?.properties?.disableJustifyAlignment === 1 && !this.vertical;
    },

    /**
     * Computes the alignment for the tabs.
     * If the tabs are vertical or justify alignment is not disabled, returns null.
     * Otherwise, returns the content alignment from the block properties.
     *
     * @returns {string|null} The alignment for the tabs or null.
     */
    tabsAlign() {
      if (this.vertical || !this.disableJustifyAlignment) {
        return null;
      }

      return this.block?.properties?.contentAlign || null;
    },

    /**
     * Determines if the navigation panel is collapsed.
     * @returns {boolean} True if the panel is collapsed, otherwise false.
     */
    isCollapsed() {
      return !!this.getValue(this.block?.properties?.collapsed);
    },
  },

  methods: {
    /**
     * Get icon
     * @param item
     * @return {string|undefined}
     */
    getIcon(item) {
      // If icon mode return icon
      if (this.viewMode === 'icon') {
        return typeof item.icon === 'string' ? item.icon : undefined;
      }

      // Get active image
      const activeImage = item.activeImage?.source_url || undefined;

      // If active tab and active image
      if (item.value === this.tab && activeImage) {
        return `img:${pathHelper.assetPath(activeImage)}`;
      }

      // Get image
      const image = item.image?.source_url || undefined;

      // Return image
      return image ? `img:${pathHelper.assetPath(image)}` : undefined;
    },
  },
}

</script>

<style lang="scss">
.navigation-panel {
  position: relative;

  .q-tab {
    padding: 0 10px;
  }

  .q-tabs--vertical {
    .q-tab {
      justify-content: start;

      label {
        margin-left:5px;
      }

    }
  }

  &.tabs-disable-justify-align {
    .q-tab {
      flex: 0 !important;
    }
  }

  &.tabs-justify-content {
    .q-tabs__content {
      justify-content: var(--tabs-justify-content);
    }
  }

  .link-connector {
    z-index: 1;
    position: absolute;
    bottom: 5%;
  }

  .navigation-panel-dropdown {
    padding-top: 10px;

    .q-btn-dropdown__arrow {
      display: none !important;
    }
  }

  .hidden-events {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);

    .link-connector {
      position: static;
    }
  }
}
</style>
