<template>
  <ui-prop-string v-if="hasParentControlList" :block="block" title="Field Alias" name="alias" :default-value="defaultValue" :untranslatable="true"/>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  components: {UiPropString},
  mixins: [propertiesMixins],
  name: "UiPropFieldAlias",
  computed: {
    /**
     * Check if the block has parent control list
     * @return {*}
     */
    hasParentControlList() {
      // Get all parents
      const parents = this.canvas.getNodeParentsById(this.block.id);
      // Check if parent is Form
      return parents.some(parent => parent?.type === 'ControlList')
    }
  }}

</script>
