<template>
  <q-tree
    v-if="isReady"
    :nodes="model"
    node-key="id"
    label-key="name"
    default-expand-all
  >
    <template #default-header="{node}">
      <q-input
        v-if="!node.is_array && node.type === 'string'"
        dense
        :label="node.name"
        :hint="`Type: ${node.type}`"
        v-model="node.value"
        class="col"
      />
      <q-input
        v-else-if="!node.is_array && ['int', 'float'].includes(node.type)"
        dense
        :label="node.name"
        :hint="`Type: ${node.type}`"
        v-model.number="node.value"
        class="col"
      />
      <q-checkbox
        v-else-if="!node.is_array && node.type === 'bool'"
        v-model.number="node.value"
        :true-value="true"
        :false-value="false"
        :label="node.name"
      />
      <div v-else v-text="node.name" />

      <q-btn
        v-if="node.is_array"
        icon="add"
        size="sm"
        flat
        dense
        class="q-ml-sm"
        @click.stop.prevent="addItem(node)"
      />

      <q-btn
        v-if="node.deletable"
        icon="delete"
        color="negative"
        size="sm"
        flat
        dense
        class="q-ml-sm"
        @click.stop.prevent="deleteItem(node)"
      />
    </template>
  </q-tree>
</template>

<script>
/* eslint-disable */
import {nanoid} from 'nanoid';
import jp from 'jsonpath';
import cloneDeep from 'lodash/cloneDeep.js';

export default {
  name: 'TreeStorageEditor',

  emits: ['update:modelValue'],

  props: {
    schema: {
      required: true,
      type: Array,
    },
    modelValue: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      model: [],
      isReady: false,
    };
  },

  computed: {
    /**
     * Computed property that generates a JSON representation of the model.
     * It processes each node recursively to build the JSON structure.
     *
     * @returns {Object} The JSON representation of the model.
     */
    contentJson() {
      /**
       * Recursively processes a node to generate its JSON representation.
       *
       * @param {Object} node - The node to process.
       * @returns {any} The JSON representation of the node.
       */
      const processNode = (node) => {
        if (node.is_array) {
          return node.children.map(processNode);
        } else if (node.children && node.children.length) {
          const processed = node.children.reduce((obj, child) => {
            obj[child.name] = processNode(child);
            return obj;
          }, {});

          if (node.isWrapper && node.originalType !== 'object') {
            return Object.values(processed).flat();
          }

          return processed;
        } else {
          return node.value;
        }
      };

      return this.model.reduce((result, node) => {
        result[node.name] = processNode(node);
        return result;
      }, {});
    },
  },

  methods: {
    /**
     * Initializes the schema by assigning unique IDs to each item and processing
     * nested objects and arrays.
     *
     * @param {Array} schema - The schema to initialize.
     * @param {string} [path='$'] - The JSONPath to the current schema node.
     * @returns {Array} The initialized schema with unique IDs and processed children.
     */
    initializeSchema(schema, path = '$') {
      return schema.map((item) => {
        item.id = nanoid(10);

        if (item.is_array) {
          if (item.children?.length) {
            item.arrayMeta = item.children;
          }

          item.children = [];
        } else if (item.type === 'object') {
          item.children = this.initializeSchema(item.children, `${path}.${item.name}`);
        }

        return item;
      });
    },

    /**
     * Adds a new item to the given node. If the node has array metadata, it initializes
     * the children with the schema defined in the array metadata. Otherwise, it adds
     * a new child with the same type as the node.
     *
     * @param {Object} node - The node to which the new item will be added.
     */
    addItem(node) {
      if (node?.arrayMeta) {
        node.children.push({
          id: nanoid(10),
          name: `${node.name}::${node.children.length + 1}`,
          type: 'object',
          is_array: 0,
          isWrapper: true,
          originalType: node.type,
          children: this.initializeSchema(cloneDeep(node.arrayMeta)),
          deletable: true,
        })
      } else {
        node.children.push({
          id: nanoid(10),
          name: `${node.name}::${node.children.length + 1}`,
          type: node.type,
          is_array: 0,
          children: [],
          value: ['int', 'float', 'bool'].includes(node.type) ? 0 : '',
          deletable: true,
        });
      }
    },

    /**
     * Deletes the specified item from the model.
     * Prompts the user for confirmation before deletion.
     *
     * @param {Object} node - The node to delete.
     */
    deleteItem(node) {
      this.$q.dialog({
        title: 'Delete item',
        message: 'Are you sure you want to delete this item?',
        ok: 'Yes',
        cancel: 'No',
      }).onOk(() => {
        const parentPath = jp.paths(this.model, `$..[?(@.id == '${node.id}')]`)[0].slice(0, -2).join('.');

        const parent = jp.value(this.model, parentPath);

        parent.children = parent.children.filter(child => child.id !== node.id);
      });
    },

    /**
     * Applies the initial result to the model by processing each node and its data.
     * It recursively processes nodes to initialize their values based on the provided modelValue.
     */
    applyInitialResult() {
      /**
       * Recursively processes a node and its data to initialize its value.
       *
       * @param {Object} node - The node to process.
       * @param {any} data - The data to assign to the node.
       */
      const processNode = (node, data) => {
        if (data === undefined) return;

        if (node.type === 'object' && node.is_array === 1) {
          const arrayData = Array.isArray(data) ? data : [];

          arrayData.forEach(() => {
            this.addItem(node);
          });

          node.children.forEach((child, index) => {
            const itemData = arrayData[index];
            child.children.forEach(field => {
              if (field.type === 'object') {
                processNode(field, itemData[field.name]);
              } else if (field.is_array === 1) {
                const arrayValue = itemData?.[field.name] || [];
                arrayValue.forEach(value => {
                  this.addItem(field);
                  const lastItem = field.children[field.children.length - 1];
                  lastItem.value = value;
                });
              } else {
                field.value = itemData?.[field.name] ?? field.value;
              }
            });
          });
        } else if (node.type === 'object') {
          node.children.forEach(child => {
            processNode(child, data[child.name]);
          });
        } else if (node.is_array === 1) {
          const arrayData = Array.isArray(data) ? data : [];
          arrayData.forEach(value => {
            this.addItem(node);
            const lastItem = node.children[node.children.length - 1];
            lastItem.value = value;
          });
        } else {
          node.value = data;
        }
      };

      this.model.forEach(node => {
        processNode(node, this.modelValue[node.name]);
      });
    },
  },

  watch: {
    model: {
      handler() {
        if (!this.isReady) {
          return;
        }

        this.$emit('update:modelValue', this.contentJson);
      },
      deep: true,
    },
  },

  created() {
    this.model = this.initializeSchema(cloneDeep(this.schema));

    this.applyInitialResult();

    this.isReady = true;
  },
}
</script>
