<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="fragment-editor-cmp" :class="{'maximized-fragment': maximized}">

    <div class="fragment-events">
      <ab-flow-link-connector :block="block" type="output" event="create"/>
      <ab-flow-link-connector :block="block" type="output" event="destroy"/>
      <ab-flow-link-connector :block="block" type="output" event="state"/>
      <ab-flow-link-connector :block="block" type="output" event="navigate-back"/>
    </div>

    <div class="fragment-cmp shadow-5">

      <div class="row fragment-header">
        <div class="fragment-label col">{{title}}</div>

        <q-btn
          v-if="designer"
          class="q-ml-sm"
          :icon="!maximized ? 'fullscreen' : 'fullscreen_exit'"
          flat
          outline
          size="sm"
          padding="xs"
          color="white"
          @click="toggleEditingMode"
        />
      </div>

      <ab-flow-link-connector v-for="(s) of globals.sides" :key="s" type="input" :class="{[`connector-${s}`]: true}" :block="block" event="navigate" :unique="s"/>
      <div
        class="fragment-content dg-background-primary dg-foreground-primary"
        :class="[classesString, `fragment-${type}`, {'fragment-content--safe-area': safeAreaInsets}]"
        @drop.prevent.stop="canvas && canvas.addBlock(this, $event)"
        :style="fragmentStyles"
      >
        <q-bar v-if="type === 'page'" dense class="mobile-bar">
          <q-icon name="email" />
          <q-space />
          <q-icon name="bluetooth" />
          <q-icon name="signal_wifi_4_bar" />
          <q-icon name="signal_cellular_4_bar" />
          <div class="gt-xs">100%</div>
          <q-icon name="battery_full" />
          <div>10:00AM</div>
        </q-bar>

        <q-scroll-area v-if="type === 'content'" style="height: 100%">
          <ab-flow-components-renderer :items="block.children"/>
        </q-scroll-area>
        <ab-flow-components-renderer v-else :items="block.children"/>
      </div>
    </div>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import _ from "lodash";
import {StorageNode} from '@/../../common/db/StorageNode'
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: null
    },
    module_id: {
      default: 0
    },
  },
  components: {AbFlowComponentsRenderer, AbFlowBaseCmp, AbFlowLinkConnector},
  provide() {
    return {
      parentFragment: this,
    }
  },
  props: ['block'],
  name: "FragmentEditorCmp",
  computed: {


    /**
     * Get storage data
     * @return {*}
     */
    storageData() {
      return this.wait("storageData", StorageNode.getTree(this.module_id, this.block.id), {})
    },

    /**
     * Widget storage
     * @return {{get: (function(*): never)}}
     */
    storage() {
      return {
        get: (key) => {
          return _.get(this.storageData, key)
        }
      }
    },


    /**
     * Fragment styles
     */
    fragmentStyles() {

      // get fragment layout
      const fl = this.block.properties?.fragmentLayout ? this.block.properties?.fragmentLayout : "mobile-portrait"

      // Getting settings
      const fls = this.globals.options?.fragmentLayouts ? this.globals.options?.fragmentLayouts.find(f => f.value === fl) : '300'

      // get width and height from properties
      const width = this.block.properties?.width ? this.block.properties?.width : fls.width
      const height = this.block.properties?.height ? this.block.properties?.height : fls.height

      // Res styles
      const resStyles = {};
      if(width) resStyles.width = width + "px"
      if(height) resStyles.height = height + "px"

      // Return frame size
      return resStyles
    },

    /**
     * Get fragment type
     */
    type() {
      return this.block?.properties?.fragmentType || 'page';
    },

    /**
     * Title
     */
    title() {
      return this.block.properties ? this.block.properties.title : "No title"
    },

    /**
     * This computed property is used to determine if the safe area insets should be applied to the block.
     * The safe area insets are applied if the `disableSafeAreaInsets` property of the block is not set to 1 and the type of the block is 'page'.
     *
     * @return {boolean} - Returns true if the safe area insets should be applied, otherwise false.
     */
    safeAreaInsets() {
      return this.block?.properties?.disableSafeAreaInsets !== 1 && this.type === 'page';
    },

    /**
     * Computed property to determine if the fragment is maximized.
     * @return {boolean} - Returns true if the fragment is maximized, otherwise false.
     */
    maximized() {
      return this.designer?.maximizedFragment === this.block.id;
    },
  },

  methods: {
    /**
     * Toggles the editing mode of the fragment.
     * If the fragment is currently maximized, it will be minimized.
     * If the fragment is currently minimized, it will be maximized.
     */
    toggleEditingMode() {
      if (!this.designer) {
        return;
      }

      if (this.maximized) {
        this.designer.minimizeFragment();
      } else {
        this.designer.maximizeFragment(this.block.id);
      }
    },
  },
}

</script>

<style lang="scss">

.fragment-editor-cmp {

  .fragment-events {
    z-index: 1;
    position: absolute;
    right:-12px;
    display: flex;
    flex-direction: column;
  }

  .fragment-cmp {
    display: flex;
    flex-direction: column;
    border: 10px solid #333;
    border-top-width: 5px;
    border-radius: 15px;
    background: #333;

    .fragment-header {
      padding-bottom: 5px;
    }

    .fragment-label {
      color: white;
      display: flex;
      align-items: center;
      height: 30px;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      background: #aaa;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    .fragment-content {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      //overflow: auto;
      width: var(--fragmentWidth);
      height: var(--fragmentHeight);

      &--safe-area {
        padding-top: var(--safe-area-top);
      }
    }

  }

  .in-component-dragging .top-hovered .editor-cmp {
    margin: 5px;
  }

  .mobile-bar {
    background: rgba(#000, .7);
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .fragment-page {
    --safe-area-top: 24px;
  }
}

</style>
