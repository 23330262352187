<template>

  <div>
    <ui-prop-string name="title" title="Title" :block="block"/>

    <ui-prop-dynamic-string :block="block" title="Endpoint" name="endpoint" untranslatable />

    <ui-prop-select
      title="HTTP Method"
      name="method"
      default-value="GET"
      :block="block"
      :options="globals.options.http_methods"
    />

    <ui-prop-custom-list-props v-if="block.properties?.method !== 'GET'" class="q-mt-md" name="payload" title="Payload" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Parameter name"/>
        <div class="row">
          <q-select class="col" v-model="data.item.type" :options="globals.options.data_types" label="Parameter type" map-options emit-value/>
          <q-checkbox label="Is array" v-model="data.item.isArray"/>
        </div>
        <value-selector v-model="data.item.value" :is-array="data.item.isArray" :title="data.item.name" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props>

    <ui-prop-value-selector class="q-mt-md" :block="block" title="Variable to save result" name="varToSaveResult" value-type="setter" :parent-diagram-id="diagram_id" />
  </div>

</template>

<script>

import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import ValueSelector from '@/components/ValueSelector/ValueSelector.vue';
import UiPropCustomListProps from '@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue';
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';

export default {
  components: {
    UiPropString,
    UiPropCustomListProps,
    ValueSelector,
    UiPropSelect, UiPropDynamicString, UiPropValueSelector},
  mixins: [propertiesMixins],
  name: "ApiRequestPropsCmp",
  computed: {
  }
}

</script>
