<template>

  <ab-flow-base-cmp :draggable="false" :block="block" :is_container="true" class="column" :class="classesString" :style="stylesString">

    <label class="text-subtitle1 bg-grey-3 q-pa-xs" v-if="title">
      {{title}}
    </label>

    <ab-flow-components-renderer :items="block.children" />

    <div class="q-pt-sm">
      <q-btn icon="add" flat color="primary">Add</q-btn>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";

export default {
  components: {AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  provide: function () {
    return {
      parentControlList: this
    }
  },
  props: ['block'],
  name: "ControlListEditorCmp",
  computed: {

    /**
     * Title
     * @return {*|string}
     */
    title() {
      return this.block.properties?.title
    }
  }
}

</script>
