<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6 q-mb-md">
      Google reCAPTCHA settings
    </div>
    <q-form @submit="saveProperties">

      <q-input
        v-if="currentModule.type === 'server'"
        label="Secret key"
        v-model="properties.secretKey"
      />
      <q-input
        v-else
        label="Site key"
        v-model="properties.siteKey"
      />

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
      </div>
    </q-form>

  </q-page>
</template>

<script>
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import {AppModule} from '../../../../../../common/db/AppModule';

export default {
  mixins: [integrationsMixin],

  name: "RecaptchaIntegrationSettings",

  data() {
    return {};
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "recaptcha", type: "recaptcha"}),

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },
  },

  methods: {},
}

</script>
