<template>
  <div v-if="localValue?.id" class="q-pa-md">

    <q-dialog ref="selectDlg">
      <q-card class="full-width">
        <q-card-section class="bg-primary text-white row">
          Select variable
          <q-space/>
          <q-btn icon="close" flat round @click="$refs.selectDlg.hide();"/>
        </q-card-section>
        <q-card-section>
          <q-tree no-selection-unset node-key="id" :nodes="treeStorageTree" v-model:selected.number="localValue.tree_storage_node"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="text-h6 bg-grey-2">Node settings</div>
    <q-form @submit="saveNode" class="q-gutter-sm">
      <q-input
          v-model.trim="localValue.name"
          label="Node name *"
          hint="Name of node for navigation"
          lazy-rules
          :rules="[ val => (val && val.length) || 'Please type something']"
          :disable="readonly"
          @keydown="preventSpace"
      />

      <div class="row">
        <q-checkbox label="Is array" v-model="localValue.is_array" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="mode==='storage'" label="Is argument" v-model="localValue.is_argument" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="localValue.is_argument || mode === 'arguments'" label="Is reference" v-model="localValue.is_reference" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="mode !== 'structure'" label="Update state" v-model="localValue.update_state" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="allowInjectableVars" label="Injectable" v-model="localValue.is_injectable" :true-value="1" :false-value="0" :disable="readonly"/>
      </div>

      <q-input
          v-model="localValue.title"
          label="Node title *"
          hint="Title of variable in storage"
          :disable="readonly"
      />

      <q-select class="col"
                v-model="localValue.type"
                label="Node type *"
                hint="Type of node value"
                lazy-rules
                :rules="[ val => (val && val.length) || 'Please type something']"
                map-options
                emit-value
                :options="dataTypes"
                :disable="readonly"
      />

      <q-select v-if="localValue.type==='db-record'"
                class="col"
                v-model="localValue.db_table"
                label="Db table *"
                hint="Database table"
                lazy-rules
                :rules="[ val => (val) || 'Please type something']"
                map-options
                emit-value
                :options="tablesList"
                :disable="readonly"
      />

      <q-field
        v-if="localValue.type==='tree-storage'"
        label="Tree storage node *"
        hint="Tree storage node"
        stack-label class="col"
        lazy-rules
        v-model="localValue.tree_storage_node"
        :rules="[ val => (val) || 'Please type something']"
      >
        <template v-slot:append>
          <q-btn icon="list" flat size="sm" @click="$refs.selectDlg.show()"/>
        </template>

        <div class="text-black cursor-pointer overflow-hidden">
          {{ selectedTreeStorageNode }}
        </div>
      </q-field>


      <div v-if="mode==='storage'">
        <div class="text-h6 bg-grey-2">Node value</div>

        <q-checkbox v-model="localValue.is_test_value" :true-value="1" :false-value="0" label="Is test value" :disable="readonly" />

        <storage-node-value-editor
            :app-id="appId"
            :module-id="moduleId"
            v-model="localValue"
            :disable="readonly"
        />
      </div>


      <div>
        <q-btn v-if="!readonly" label="Save" type="submit" color="primary"/>
      </div>
    </q-form>

  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep.js';
import {StorageNode} from "@/../../common/db/StorageNode.js";
import {DbModule} from "@/../../common/db/DbModule.js";
import {DbTable} from "@/../../common/db/DbTable.js";
import StorageNodeValueEditor from "@/pages/workspace/storage/StorageNodeValueEditor.vue";

export default {
  components: {StorageNodeValueEditor},

  emits: ['update:modelValue'],

  props: {
    moduleId: {},
    mode: {
      default: "storage"
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: true,
      type: Object,
    },
    allowInjectableVars: {
      type: Boolean,
      default: true,
    },
    allowedDataTypes: {
      required: false,
      type: Array,
      default: () => [],
    },
    allowValueEditing: {
      type: Boolean,
      default: true,
    },
  },

  name: "JsonStorageNodeValue",

  data() {
    return {
      localValue: false,
    };
  },

  async created() {
    this.localValue = cloneDeep(this.modelValue);
  },

  methods: {
    /**
     * Save node
     * @return {Promise<void>}
     */
    async saveNode()  {
      this.$emit('update:modelValue', this.localValue);
    },

    /**
     * Prevents the default action of adding a space character when the spacebar is pressed.
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by pressing a key.
     */
    preventSpace(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
  },

  computed: {

    /**
     * Get app id from route
     * @return {*}
     */
    appId() {
      return this.$route.params.app_id
    },

    /**
     * Project tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {

        // Tables object list
        const tables = []

        // Load database tables
        for(const db of await DbModule.query().where("app_id", this.appId).get()) {
          for(const table of await DbTable.query().where("db_id", db.id).get()) {
            tables.push({
              value: table.id,
              label: `${db.name}.${table.name}`
            })
          }
        }

        // Return tables
        return tables
      }, []);
    },

    /**
     * Data types
     * @return {*[]}
     */
    dataTypes() {

      const dataTypes = [
        ...(this.globals.options.data_types),
        ...[
          {value: "db-record", label: "DB record"},
          {value: "tree-storage", label: "Tree storage"},
        ],
      ];

      if (!this.allowedDataTypes?.length) {
        return dataTypes;
      }

      return dataTypes.filter(({value}) => this.allowedDataTypes.includes(value));
    },

    /**
     * Fetches the tree storage nodes for the current application and module.
     *
     * @returns {Promise<Array>} A promise that resolves to an array of tree storage nodes.
     */
    treeStorageNodes() {
      return this.wait('treeStorageNodes', StorageNode.query().where({
        app_id: this.appId,
        module_id: this.moduleId,
        block_id: 'tree-storage',
      }).get(), []);
    },

    /**
     * Constructs a tree structure from the given nodes.
     *
     * @returns {Array} The tree structure with nodes.
     */
    treeStorageTree() {
      /**
       * Recursively stores nodes as a tree with key, label, and children.
       *
       * @param {Array} nodes - The list of nodes.
       * @param {number} parent_id - The parent node ID.
       * @returns {Array} The tree structure.
       */
      const tree = (nodes, parent_id) => nodes.filter(node => node.parent_id === parent_id).map(node => ({
        id: node.id,
        label: node.name,
        children: tree(nodes, node.id),
      }));

      // Return tree
      return tree(this.treeStorageNodes, 0);
    },

    /**
     * Computes the path of the selected tree storage node.
     *
     * @returns {string} The path of the selected tree storage node, or an empty string if no node is selected.
     */
    selectedTreeStorageNode() {
      if (!this.localValue.tree_storage_node) {
        return '';
      }

      const path = [];

      let id = this.localValue.tree_storage_node;

      while (id) {
        const node = this.treeStorageNodes.find(n => n.id === id);

        if (!node) {
          break;
        }

        path.push(node.name);

        id = node.parent_id;
      }

      return path.reverse().join('.');
    },
  }
}

</script>
