<template>

  <div>
    <ui-prop-string name="title" title="Dialog title" :block="block"/>

    <ui-prop-dynamic-string
      :multiple="true"
      :block="block"
      title="Instruction"
      name="instruction"
      untranslatable
    />

    <ui-prop-dynamic-string
      :multiple="true"
      :block="block"
      title="Result count"
      name="count"
      untranslatable
      default-value="1"
    />


    <ui-prop-json-schema
      :block="block"
      title="Schema"
      name="schema"
    />

    <div class="q-mt-md">Tree Schema constructor (like in aiHelper.generateSchema)</div>

  </div>

</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiPropJsonSchema from '@/components/DiagramDesigner/Editor/properties/UiPropJsonSchema.vue';

export default {
  components: {UiPropJsonSchema, UiPropDynamicString, UiPropString},
  mixins: [propertiesMixins],
  props: ['block'],
  name: "SchemaGeneratorPropsCmp",
}

</script>
