<template>

  <ui-prop-string :block="block" title="Title" name="title"/>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-dynamic-props :block="block"/>

</template>

<script>

import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import validatableFormFieldMixin
  from "@/components/DiagramDesigner/Editor/components/UI/Form/validatableFormFieldMixin";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
export default {
  mixins: [propertiesMixins, validatableFormFieldMixin],
  name: "ControlListPropsCmp",
  props: ['block'],
  components: {UiPropString, UiPropValueSelector, UiDynamicProps},
  data: () => ({
    defaultSchema: [
      {
        name: "name",
        type: "string"
      },
      {
        name: "value",
        type: "string"
      }
    ]
  }),
}

</script>

