<template>
  <q-card class="border">
    <q-card-section class="bg-primary text-white">
      Schema
    </q-card-section>
    <q-card-section>
      <div class="schema-preview">
        <pre>{{ JSON.stringify(block?.properties?.schema?.schema || {}, null, 2) }}</pre>
      </div>

      <q-separator spaced />

      <q-card-actions class="justify-end">
        <q-btn @click="$refs.editor.show()" label="Edit schema" flat icon="edit" />
      </q-card-actions>
    </q-card-section>

    <q-dialog ref="editor" full-width full-height>
      <q-card class="flex column">
        <q-card-section>
          <q-tabs v-model="tab">
            <q-tab name="structure" label="Structure"></q-tab>
            <q-tab name="schema" label="Schema"></q-tab>
          </q-tabs>
        </q-card-section>

        <q-card-section class="col scroll">
          <q-tab-panels v-model="tab" class="col">
            <q-tab-panel name="structure">
              <json-storage-tree
                v-model="currentValue.structure"
                :allowed-data-types="['string', 'int', 'float', 'bool', 'object']"
                :horizontal="false"
                mode="structure"
              />
            </q-tab-panel>

            <q-tab-panel name="schema">
              <tree-storage-editor :schema="currentValue.structure" v-model="currentValue.schema" />
            </q-tab-panel>
          </q-tab-panels>
        </q-card-section>

        <q-separator spaced />

        <q-card-actions class="justify-end">
          <q-btn label="OK" color="primary" @click="$refs.editor.hide();" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';
import JsonStorageTree from '@/pages/workspace/storage/JsonStorageTree.vue';
import TreeStorageEditor from '@/pages/workspace/tree-storage/TreeStorageEditor.vue';

export default {
  name: 'UiPropJsonSchema',
  components: {TreeStorageEditor, JsonStorageTree},

  mixins: [propertiesMixins],

  data() {
    return {
      tab: 'structure',
    };
  },

  created() {
    if(!this.currentValue || Object.keys(this.currentValue).length === 0) {
      this.currentValue = {
        structure: [],
        schema: {},
      };
    }
  },
}
</script>

<style scoped lang="scss">
.schema-preview {
  overflow: hidden;
  max-height: 200px;
  font-size: 12px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 30px 30px rgba(255, 255, 255, 0.8);
  }
}
</style>
